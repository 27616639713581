import * as React from 'react';

/**
 * CoreComponent component
 * @extends {React.PureComponent<IScriptInjectorProps>}
 */

class Leblanc extends React.PureComponent {
	constructor(props:{}){
		super(props);
	}

	public render(): JSX.Element | null {
		return <script />;
	}
}

export default Leblanc;
