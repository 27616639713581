import * as React from 'react';
import { IEplpContainerViewProps } from './eplp-container';

export default (props: IEplpContainerViewProps) => {
	return (
		<>
			<h1 className="eplp">{props.heading}</h1>
			{(props.slots.top && props.slots.top[0])}
			<div className='row featured-tiles mb-3'>
				<div className="col-lg-4 col-sm-6">
					{(props.slots.square1 && props.slots.square1[0])}
				</div>
				<div className="col-lg-4 col-sm-6">
					{(props.slots.square2 && props.slots.square2[0])}
				</div>
				<div className="col-lg-4 col-sm-6">
					{(props.slots.square3 && props.slots.square3[0])}
				</div>
			</div>
			<div className="row featured-tiles mb-3">
				<div className="col-lg-4 col-sm-6">
					{(props.slots.square4 && props.slots.square4[0])}
				</div>
				<div className="col-lg-4 col-sm-6">
					{(props.slots.square5 && props.slots.square5[0])}
				</div>
				<div className="col-lg-4 col-sm-6">
					{(props.slots.square6 && props.slots.square6[0])}
				</div>
			</div>
		</>
	);
};
