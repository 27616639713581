import { ContentEditableEvent, Text } from '@msdyn365-commerce/core';
import { LogLevel } from '@msdyn365-commerce/telemetry-internal';
import React from 'react';
import { IDefaultContainer4SlotsData } from './default-container-4-slots.data';
import { IDefaultContainer4SlotsProps } from './default-container-4-slots.props.autogenerated';

/**
 * DefaultContainer4Slots component
 * @extends {React.PureComponent<IDefaultContainer4SlotsProps<IDefaultContainer4SlotsData>>}
 */
class DefaultContainer4Slots extends React.PureComponent<IDefaultContainer4SlotsProps<IDefaultContainer4SlotsData>> {
	constructor(props: IDefaultContainer4SlotsProps<IDefaultContainer4SlotsData>){
		super(props);
	}
	public render():JSX.Element {
		const {slots, telemetry, config, data} = this.props;
		const content = slots && slots.content;
		const heading = config.heading || data.heading;
		if (content && content.length > 4) {
			telemetry.log(LogLevel.Warning, 'Default 4 Slot Container passed more than 4 slots!');
		}
		return (
			<div className={`default-container-4-slots ${config.containerType} ${ config.className}`}>
				{heading && <Text
                    className={"heading container-heading"}
                    tag={heading.tag || "h2"}
                    text={heading.headingText}
                    editProps={{ onEdit: this.handleTextChange, requestContext: this.props.context.request }}
                />}
				<div className="row">
					{content && content[0] && <div className={"col-6 col-md-3"}>{content[0]}</div>}
					{content && content[1] && <div className={"col-6 col-md-3"}>{content[1]}</div>}
					{content && content[2] && <div className={"col-6 col-md-3"}>{content[2]}</div>}
					{content && content[3] && <div className={"col-6 col-md-3"}>{content[3]}</div>}
				</div>
			</div>
		)
	}

	public handleTextChange = (event: ContentEditableEvent) => (this.props.config.heading!.headingText = event.target.value);
}
export default DefaultContainer4Slots;