import * as React from 'react';
import { IEplpContainerData } from './eplp-container.data';
import { IEplpContainerProps } from './eplp-container.props.autogenerated';

export interface IEplpContainerViewProps extends IEplpContainerProps<IEplpContainerData> {
	heading?: string;
}

/**
  * EplpContainer component
 * @extends {React.PureComponent<IEplpContainerProps<IEplpContainerData>>}
 */
class EplpContainer extends React.PureComponent<IEplpContainerProps<IEplpContainerData>> {
	public render(): JSX.Element | null {
		const { config } = this.props;
		const EplpContainerProps = {
			...this.props,
			heading: (config.heading || null)
		};
		return this.props.renderView(EplpContainerProps);
	}
}

export default EplpContainer;